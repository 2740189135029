import React, { createContext, useContext, useState, useEffect } from "react";
// import authservice from "../../src/services/auth.service";

const LoadingContext = createContext();

export const LoadingProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [offCanvasShow, setOffcanvasShow] = useState(false);
  const [orderConfirm, setOrderConfirm] = useState(false);
  const [orderStatus, setOrderStatus] = useState(false);
  const [selectedCheckoutData, setSelectedCheckoutData] = useState(0);

  return (
    <LoadingContext.Provider
      value={{
        loading,
        setLoading,
        offCanvasShow,
        setOffcanvasShow,
        orderConfirm,
        setOrderConfirm,
        selectedCheckoutData,
        setSelectedCheckoutData,
        orderStatus,
        setOrderStatus,
      }}
    >
      {children}
    </LoadingContext.Provider>
  );
};

export const useLoading = () => {
  return useContext(LoadingContext);
};
