import Modal from "react-bootstrap/Modal";
import React from "react";

const ReadMore = (props) => {
  return (
    <Modal
      className="review-detail-modal"
      show={props.showReadMore}
      onHide={() => props.setShowReadMore(false)}
      centered
    >
      <Modal.Header className="" closeButton>
        <Modal.Title className="offcanvas-title">
          Rahul Kumar <span className="stars">★★★★★ </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-container">
        <p className="review-read-data">
          Designers Collab is more than just a coffee shop web application—it’s
          a vibrant hub for coffee lovers, redefining how we discover and enjoy
          coffee products online. Whether you’re a casual drinker, an aspiring
          barista, or a seasoned connoisseur, this platform offers a holistic
          coffee experience that caters to every need and preference. From the
          moment you enter the website, you’re welcomed into a digital space
          that exudes warmth and sophistication, much like the perfect cup of
          coffee.
        </p>
      </Modal.Body>
    </Modal>
  );
};

export default ReadMore;
