import React from "react";
import {
  CNavItem,
  CSidebarBrand,
  CSidebarHeader,
  CSidebarNav,
} from "@coreui/react";
import "../Login/Login.css";
import { GrHomeRounded } from "react-icons/gr";
import { PiSquaresFour } from "react-icons/pi";
// import { BsBasket } from "react-icons/bs";
import { GoPerson } from "react-icons/go";
import { IoAlertCircleOutline } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import { CiStar } from "react-icons/ci";

const Sidebar = () => {
  const location = useLocation();
  // const [activeTab, setActiveTab] = useState("home");

  const handleTabClick = (tab) => {
    // setActiveTab(tab);
    navigate(`/${tab}`)
  };
  const navigate = useNavigate();

  return (
    // <Container fluid>
    //   <Row className="main_container">
    //   <Col lg={2} className="outer_sidebar padding_0">
        <>
      <CSidebarHeader className="sidebar_custom_header">
        <CSidebarBrand>
          <img src="./images/sidebarImg.svg" alt="Sidebar logo" />
        </CSidebarBrand>
      </CSidebarHeader><CSidebarNav>
        <CNavItem
          href="#"
          onClick={() => handleTabClick("home")}
          className={location?.pathname === "/home" ? "active" : ""}
        >
          <div className="sidebar_items">
            <GrHomeRounded className="sideBar_icons" />
            Home
          </div>
        </CNavItem>
        <CNavItem
          href="#"
          onClick={() => handleTabClick("menu")}
          className={location?.pathname === "/menu" ? "active" : ""}
        >
          <div className="sidebar_items">
            <PiSquaresFour className="sideBar_icons" />
            Menu
          </div>
        </CNavItem>
        {/* <CNavItem
          href="#"
          onClick={() => handleTabClick("subScriptions")}
          className={location?.pathname === "/subScriptions" ? "active" : ""}
        >
          <div className="sidebar_items">
            <BsCardChecklist className="sideBar_icons" />
            Elite Pass
          </div>
        </CNavItem> */}
         <CNavItem
          href="#"
          onClick={() => handleTabClick("buyers")}
          className={location?.pathname === "/buyers" ? "active" : ""}
        >
          <div className="sidebar_items">
            <GoPerson className="sideBar_icons" />
            Buyers
          </div>
        </CNavItem>
        <CNavItem
          href="#"
          onClick={() => handleTabClick("profile")}
          className={location?.pathname === "/profile" ? "active" : ""}
        >
          <div className="sidebar_items">
            <GoPerson className="sideBar_icons" />
            Profile
          </div>
        </CNavItem>
        <CNavItem
          href="#"
          onClick={() => handleTabClick("reviews")}
          className={location?.pathname === "/reviews" ? "active" : ""}
        >
          <div className="sidebar_items">
            <CiStar className="sideBar_icons" />
            Reviews
          </div>
        </CNavItem>
        <CNavItem
          href="#"
          // onClick={() => handleTabClick("help")}
          className={location?.pathname === "/help" ? "active" : ""}
        >
          <div className="sidebar_items">
            <IoAlertCircleOutline className="sideBar_icons" />
            Help
          </div>
        </CNavItem>
      </CSidebarNav></>
  );
};

export default Sidebar;
