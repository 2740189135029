import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { Col, Container, Row } from "react-bootstrap";
import Select from "react-select";
import { PostJsonData } from "../Api/globalApi";
import { useLoading } from "../Context/LoadingContext";

function OrderConfirmationModal(props) {
  const { setLoading, selectedCheckoutData, orderConfirm, setOrderConfirm } =
    useLoading();
  const sessionKey = localStorage.getItem("sessionKey");
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState({
    value: 0,
    label: "Choose Buyer",
  });
  const [buyerOptions, setBuyerOptions] = useState([
    { value: 0, label: "Choose Buyer", isDisabled: true },
  ]);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchBuyers = async () => {
      const payload = {
        isFilter: 1,
        isSubscription: 0,
        pageNo: 0,
        searchText: "",
      };

      setLoading(true);

      try {
        const response = await PostJsonData("/Api/getBuyers", payload);

        if (response && response.data && Array.isArray(response.data)) {
          const guestUser = { value: 1, label: "Guest User" };
          const options = response.data.map((buyer) => ({
            value: buyer.buyerID,
            label: buyer.name,
          }));

          const updatedOptions = [
            { value: 0, label: "Choose Buyer", isDisabled: true },
            guestUser,
            ...options.filter((opt) => opt.value !== 1),
          ];

          setBuyerOptions(updatedOptions);
        } else {
          console.log("Invalid response format:", response);
          setError("Failed to load buyers. Please try again.");
        }
      } catch (err) {
        console.log("Error fetching buyers:", err);
        setError("Error fetching buyers.");
      } finally {
        setLoading(false); // Ensure loading stops
      }
    };

    fetchBuyers();
  }, []);

  const handleCheckout = async () => {
    if (selectedPaymentMethod.value === 0) {
      setError("Please select a valid buyer.");
      return;
    } else {
      setError("");
    }

    const payload = {
      buyerID: selectedPaymentMethod.value,
      description: "",
      sessionKey: sessionKey,
    };
    setLoading(true);
    try {
      const response = await PostJsonData("/Api/setOrder", payload);
      if (response.apiResponseCode === "1001") {
        setLoading(false);
        setOrderConfirm(false);
        props.setOrderStatus(true);
      } else {
        setLoading(false);
        setError(response.apiResponseMsg || "Failed to place the order.");
      }
    } catch (error) {
      setLoading(false);
      console.log("Error placing the order:", error);
      setError("Failed to place the order.");
    }
  };

  return (
    <Modal
      className="order_custom_modal"
      show={orderConfirm}
      onHide={() => setOrderConfirm(false)}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="offcanvas-title">
          Order Confirmation
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-container">
        <div className="outer_order_content">
          <Form>
            <Form.Group controlId="paymentMethod">
              <Select
                className="custom_select"
                options={buyerOptions}
                value={selectedPaymentMethod}
                onChange={(option) => {
                  setSelectedPaymentMethod(option);
                  setError("");
                }}
                filterOption={(candidate, input) => {
                  // Always include the guest user (value === 1)
                  if (candidate?.data?.value === 1) {
                    return true;
                  }
                  // Filter based on label match for other options
                  return candidate?.label
                    ?.toLowerCase()
                    ?.includes(input?.toLowerCase());
                }}
                noOptionsMessage={() => "No other buyers found"}
              />
            </Form.Group>
            {error && <p className="text-danger mt-2">{error}</p>}
          </Form>
          <div className="order-summary">
            <div className="summary-row">
              <span>Subtotal</span>
              <span>${selectedCheckoutData}</span>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer style={{ border: "none", paddingTop: "0" }}>
        <Container fluid>
          <Row>
            <Col lg={4} className="p-0">
              <div className="total_price_order">Total Price</div>
              <div className="total_price_order_amnt">
                ${selectedCheckoutData}
              </div>
            </Col>
            <Col lg={8} className="p-0">
              <Button className="order_pay_now" onClick={handleCheckout}>
                Place Order
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal.Footer>
    </Modal>
  );
}

export default OrderConfirmationModal;
