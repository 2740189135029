import {
  CButton,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CFormFeedback,
  CContainer,
  CRow,
} from "@coreui/react";
import React, { useState } from "react";
import Swal from "sweetalert2"; // Import SweetAlert2 for error messages
import { PostJsonData } from "../Api/globalApi"; // Ensure this function is correctly imported
import ResetPassword from "./ResetPassword";
import { useNavigate } from "react-router-dom";
import { useLoading } from "../Context/LoadingContext";

const ForgotPassword = (props) => {
  const navigate = useNavigate();
  const {setLoading} = useLoading();
  const validated = false
  const [showErrors, setShowErrors] = useState(false);
  const [forgotEmail, setForgotEmail] = useState('');
  const [otpShow,setOtpShow] = useState(false);
  const [resetPassword,setResetPassword] = useState(false);
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowErrors(true);

    if (!forgotEmail || !emailPattern.test(forgotEmail)) {
      return;
    }
    try {
      setLoading(true);
      // Call the /forgotPassword API
      const response = await PostJsonData("/forgotPassword", {
        username: forgotEmail.trim(),
      }); // Trim whitespace from forgotEmail

      if (response.apiResponseCode === "1001") {
        setLoading(false);
        // setOtpShow(true); // Show OTP input on success
        navigate("/otp", { state: { userName: forgotEmail } });
      } else {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops",
          text: response.apiResponseMsg || "Failed to send verification code.",
        });
      }
    } catch (error) {
      setLoading(false);
      console.log("Forgot Password API failed:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.message || "An unexpected error occurred.",
      });
    }
  };

  return (
    <>
      <CContainer fluid className="outer_container m-0 p-0">
        <CRow className="w-100 m-0">
          <CCol className="first_half">
            <img src="./images/loginImage.svg" alt="Login Illustration" />
          </CCol>
          <CCol className="second_half">
            <div className="inner_second_half">
              <div className="heading_log">Forgot Password</div>
              <CForm
                className="form_custom"
                noValidate
                validated={validated}
                onSubmit={handleSubmit}
              >
                <div className="">
                  <CFormLabel className="label_custom">
                    Please enter your Email address to receive a verification
                    code.
                  </CFormLabel>
                </div>
                <div className="mb-3">
                  <CFormLabel className="label_custom">Email</CFormLabel>
                  <CFormInput
                    type="forgotEmail"
                    placeholder="Enter your Email"
                    value={forgotEmail}
                    onChange={(e) => setForgotEmail(e.target.value)}
                    required
                    invalid={
                      showErrors &&
                      (!forgotEmail ||
                        !emailPattern.test(forgotEmail))
                    }
                  />
                  <CFormFeedback invalid>
                    {showErrors && !forgotEmail
                      ? "Email is required."
                      : "Email is invalid."}
                  </CFormFeedback>
                </div>
                <CButton type="submit" className="signIn_btn mt-3">
                  Continue
                </CButton>
              </CForm>
            </div>
          </CCol>
        </CRow>
      </CContainer>
      {/* {otpShow && !resetPassword && (
        <Otp
          forgotEmail={forgotEmail}
          setForgotEmail={setForgotEmail}
          setResetPassword={setResetPassword}
        />
      )} */}
      {/* {passwordShow &&(!otpShow)&& (<ForgotPassword forgotEmail={forgotEmail} setForgotEmail={setForgotEmail} otpShow={otpShow} setOtpShow={setOtpShow}/>)} */}
      {resetPassword && (
        <ResetPassword
          resetPassword={resetPassword}
          setResetPassword={setResetPassword}
          otpShow={otpShow}
          setOtpShow={setOtpShow}
        />
      )}
    </>
  );
};

export default ForgotPassword;
