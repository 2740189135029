import React, { useState, useEffect } from "react";
import Header from "../Common/Header";
import { Col, Container, Form, Row } from "react-bootstrap";
import Sidebar from "../Common/Sidebar";
import { RiEditLine } from "react-icons/ri";
import ProfileModal from "./profileModal";
import { CFormInput, CFormLabel } from "@coreui/react";
import { PostJsonData } from "../Api/globalApi";
import { useLoading } from "../Context/LoadingContext";

const ProfileComponent = () => {
  const {setLoading} = useLoading();
  const [profileShow, setProfileShow] = useState(false);
  const [profileData, setProfileData] = useState(null);
  const userID = localStorage.getItem("UserID"); // Retrieve userID from localStorage

  // Fetch profile data on component mount
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        setLoading(true);
        const response = await PostJsonData("/Api/getProfile", { userID: parseInt(userID, 10) });
        setProfileData(response.profileData[0]); // Assuming response has a `data` property with the profile details
        setLoading(false);
      } catch (error) {
        console.log("Error fetching profile data:", error);
        setLoading(false);
      }
    };

    fetchProfile();
  }, [userID]);

  return (
    <>
      {profileShow && <ProfileModal profileShow={profileShow} setProfileShow={setProfileShow} />}
      <Container fluid>
        <Row className="main_container">
          <Col lg={2} className="outer_sidebar padding_0">
            <Sidebar />
          </Col>

          <Col lg={10} className="outer_main_container">
            <div className="main_home_outer">
              <Header />
              <Container fluid>
                <div className="category profile_bg">
                  <div className="profile_main">
                    <Row className="mb-5" style={{ alignItems: "center" }}>
                      <Col lg={2} className="profile_photo">
                        <div className="image_profile_cont">
                          <img src={profileData?.profilePicture || "./Rectangle 2586.svg"} alt="profile" />
                          <div className="profile_edit_outer">
                            <RiEditLine className="profile_edit" />
                          </div>
                        </div>
                      </Col>
                      <Col className="profile_name" lg={10}>
                        {profileData?.firstName} {profileData?.lastName}
                      </Col>
                    </Row>
                    <div className="form-section">
                      <h6 className="mb-4">Personal details</h6>
                      <Row className="profile_name_email">
                        <Col lg={4} className="form-group">
                          <CFormLabel>Name</CFormLabel>
                          <CFormInput type="text" value={profileData?.firstName} readOnly />
                        </Col>
                        <Col lg={4} className="form-group">
                          <CFormLabel>Surname</CFormLabel>
                          <CFormInput type="text" value={profileData?.lastName} readOnly />
                        </Col>
                      </Row>
                      <Row className="profile_name_email">
                        <Col lg={4} className="form-group">
                          <CFormLabel>Phone number</CFormLabel>
                          <CFormInput type="text" value={profileData?.mobileNumber} readOnly />
                        </Col>
                        <Col lg={4} className="">
                          <CFormLabel className="gender-label-profile">Gender</CFormLabel>
                          <div className="gender-profile">
                            <Form.Check
                              type="radio"
                              label="Male"
                              className="custom_radio_gender"
                              name="gender"
                              checked={profileData?.gender === "Male"}
                              readOnly
                            />
                            <Form.Check
                              type="radio"
                              label="Female"
                              className="custom_radio_gender"
                              name="gender"
                              checked={profileData?.gender === "Female"}
                              readOnly
                            />
                            <Form.Check
                              type="radio"
                              label="Unspecified"
                              className="custom_radio_gender"
                              name="gender"
                              checked={!["Male", "Female"].includes(profileData?.gender)}
                              readOnly
                            />
                          </div>
                        </Col>
                      </Row>
                      <div className="custom-profile-check">
                        <input type="checkbox" checked={profileData?.marketingConsent} readOnly />
                        <div className="custom-pro-check-text">Do you agree to marketing emails?</div>
                      </div>
                    </div>
                  </div>
                </div>
              </Container>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ProfileComponent;
