import React from 'react';
import { Modal, Button, Dropdown } from 'react-bootstrap';
// import './SubscriptionPlanModal.css';

const SubscriptionPlanModal = (props) => {
    return (
        <Modal size='lg' className='subPlan-modal' show={props.subPlanShow} onHide={() => props.setSubPlanShow(false)} centered>
            <Modal.Header closeButton>
                <Modal.Title className='offcanvas-title p-1'>designers collab. plus</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h5 className='subtitle mt-5 mb-5'>Select your plan</h5>
                <div className='plan-container'>
                    <div className='plan-card'>
                        <div className='plan-content'>
                            <div className='plan-icon'><img src="./images/coffe-sub-plan.svg" alt="" /></div>
                            <div>
                                <h6>Premium Coffee Plan</h6>
                                <p>$150</p>
                                <div className='buy-now-outer'>
                                    <Dropdown className='sub-plan-dropdown'>
                                        <Dropdown.Toggle variant="light" id="dropdown-basic">
                                            Weekly
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item href="#">Weekly</Dropdown.Item>
                                            <Dropdown.Item href="#">Monthly</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <Button className='buy-now-btn' onClick={() => { props.setSubDetailModal(true); props.setSubPlanShow(false) }}>Subscribe Now</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='plan-card'>
                        <div className='plan-content'>
                            <div className='plan-icon'><img src="./images/coffe-sub-plan.svg" alt="" /></div>
                            <div>
                                <h6>Premium Coffee Plan</h6>
                                <p>$100</p>
                                <div className='buy-now-outer'>
                                    <Dropdown className='sub-plan-dropdown'>
                                        <Dropdown.Toggle id="dropdown-basic">
                                            Weekly
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item href="#">Weekly</Dropdown.Item>
                                            <Dropdown.Item href="#">Monthly</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <Button className='buy-now-btn' onClick={() => { props.setSubDetailModal(true); props.setSubPlanShow(false) }}>Subscribe Now</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default SubscriptionPlanModal;
