import React from 'react';
import { Modal,  Col, Row } from 'react-bootstrap';
// import './SubscriptionModal.css';

const SubscriptionModal = (props) => {
    return (
        <Modal size='xl' className='sub-modal' show={props.subModalShow} onHide={() => props.setSubModalShow(false)} centered>
            <Modal.Header closeButton>
                <Modal.Title className='offcanvas-title p-1'>designers collab. Elite Pass:</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className='tier-outer'>
                    <Col className="subscription-option collab">
                        <h5><span className='tier'>Tier 1:</span> Rise and Shine</h5>
                        <p className='upper-tier'>This includes one beverage per day of the following:</p>
                        <p className='mid-tier'>Hot and Iced Americanos, Hot and Iced Double Espressos, Hot and Iced Teas, 8 oz drinks</p>
                        <p className='mid-tier'>Free alternative milks and flavors</p>
                        <div className='outer-sub-btn'>
                            <div className='tier-price'>$14.99/Week</div>
                            <button className='get-sub-btn-2' onClick={() => { props.setSubDetailModal(true); props.setSubModalShow(false) }}>Get Subscription</button>
                        </div>
                    </Col>
                    <Col className="subscription-option collab ">
                        <h5><span className='tier'>Tier 2:</span> Tasters</h5>
                        <p className='upper-tier'>This includes one beverage per day (any size)</p>
                        <p className='mid-tier'>Free alternative milks and flavors</p>
                        <p className='mid-tier'>20% off pastries and food</p>
                        <div className='outer-sub-btn'>
                            <div className='tier-price'>$19.99/Week</div>
                            <button className='get-sub-btn-2' onClick={() => { props.setSubDetailModal(true); props.setSubModalShow(false) }}>Get Subscription</button>
                        </div>
                    </Col>
                    <Col className="subscription-option collab ">
                        <h5><span className='tier'>Tier 3:</span> Indulge</h5>
                        <p className='upper-tier'>This includes up to 2 beverages per day (any size)</p>
                        <p className='mid-tier'>Free alternative milks and flavors</p>
                        <p className='mid-tier'>20% off pastries and food</p>
                        <p className='mid-tier'>20% off on all designers collab. label items</p>
                        <div className='outer-sub-btn'>
                            <div className='tier-price'>$29.99/Week</div>
                            <button className='get-sub-btn-2' onClick={() => { props.setSubDetailModal(true); props.setSubModalShow(false) }}>Get Subscription</button>
                        </div>
                    </Col>
                </Row>
                <Col>
                    <h5 className='ms-2'>Rules:</h5>
                    <ul>
                        <li>
                            Coffees must be pre-ordered
                        </li>
                        <li>
                            Benefits cannot be transferred                        </li>
                        <li>
                            Only 1-2 coffees per day (depending on tier) ex: if on tier 2 only 1 coffee per day should be allowed.                        </li>
                        <li>
                            45 day lapse period if cancellation occurs                         </li>
                        <li>
                            This is a membership, and will be billed recurring weekly.                        </li>

                    </ul>
                </Col>
            </Modal.Body>
        </Modal>
    );
};

export default SubscriptionModal;
