import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import { PostJsonData } from "../Api/globalApi";
import Swal from "sweetalert2";
import { useLoading } from "../Context/LoadingContext";

const ModalCard = ({ show, setShow, selectedProduct }) => {
  const { setOffcanvasShow, setLoading } = useLoading();
  const sessionKey = localStorage.getItem("sessionKey");
  const [count, setCount] = useState(1);
  const [variations, setVariations] = useState(null);
  const [selectedSyrup, setSelectedSyrup] = useState(null);
  const [selectedVariations, setSelectedVariations] = useState({});
  const [selectedPrices, setSelectedPrices] = useState({});

  const increment = () => setCount(count + 1);
  const decrement = () => {
    if (count > 1) setCount(count - 1);
  };

  useEffect(() => {
    if (selectedProduct) {
      fetchProductVariations(
        selectedProduct.categoryID,
        selectedProduct.productID
      );
    }
  }, [selectedProduct]);

  const fetchProductVariations = async (categoryID, productID) => {
    setLoading(true);
    try {
      const data = await PostJsonData("/getProductVariations", {
        categoryID,
        productID,
      });
      setLoading(false);
      setVariations(data?.data);
    } catch (error) {
      setLoading(false);
      console.log("Error fetching product variations:", error);
    }
  };

  const syrupOptions = variations
    ?.find((v) => v.name === "Syrups")
    ?.values.map((syrup) => ({
      value: syrup.productVariationValueID,
      label: syrup.title,
      price: syrup.price,
    }));

  const handleAddToCart = async (e) => {
    e.preventDefault();

    let validationErrors = {};
    if (!selectedSyrup) {
      validationErrors.selectedSyrup = "Please select a syrup.";
    }
    variations?.forEach((variation) => {
      if (variation.name !== "Syrups" && !selectedVariations[variation.name]) {
        validationErrors[variation.name] = `Please select a ${variation.name}.`;
      }
    });
    if (Object.keys(validationErrors).length > 0) return;

    const productVariationValueIDs = [
      ...(selectedSyrup ? [selectedSyrup.value] : []),
      ...Object.values(selectedVariations),
    ];

    const payload = {
      productID: selectedProduct.productID,
      productVariationValueID: productVariationValueIDs,
      quantity: count,
      sessionKey: sessionKey,
    };
    setLoading(true);
    try {
      const response = await PostJsonData("/setProductCart", payload);

      if (response.apiResponseCode === "1001") {
        setLoading(false);
        Swal.fire({
          title: "Success!",
          text: response.apiResponseMsg,
          icon: "success",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            setOffcanvasShow(true);
          }
        });
        setShow(false);
      } else {
        setLoading(false);
        Swal.fire({
          title: "Error!",
          text: response.apiResponseMsg || "Something went wrong!",
          icon: "error",
        });
      }
    } catch (error) {
      setLoading(false);
      console.log("Error adding to cart:", error);
      Swal.fire({
        title: "Error!",
        text: "Failed to add product to cart.",
        icon: "error",
      });
    }
  };

  const handleVariationChange = (variationName, valueId, price) => {
    setSelectedVariations((prev) => ({ ...prev, [variationName]: valueId }));
    setSelectedPrices((prev) => ({ ...prev, [variationName]: price }));
  };

  const handleSyrupChange = (selectedOption) => {
    setSelectedSyrup(selectedOption);
    setSelectedPrices((prev) => ({ ...prev, Syrup: selectedOption?.price }));
  };

  useEffect(() => {
    if (variations?.length > 0) {
      const defaultSelections = {};
      const defaultPrices = {};
      let defaultSyrup = null;

      variations.forEach((variation) => {
        if (variation.values?.length > 0) {
          if (variation.name === "Syrups") {
            defaultSyrup = {
              value: variation.values[0]?.productVariationValueID,
              label: variation.values[0]?.title,
              price: variation.values[0]?.price,
            };
            defaultPrices.Syrup = variation.values[0]?.price;
          } else {
            defaultSelections[variation.name] =
              variation.values[0]?.productVariationValueID;
            defaultPrices[variation.name] = variation.values[0]?.price;
          }
        }
      });

      setSelectedVariations(defaultSelections);
      setSelectedPrices(defaultPrices);
      setSelectedSyrup(defaultSyrup);
    }
  }, [variations]);

  const calculateTotalPrice = () => {
    const basePrice = selectedProduct?.price || 0;
    const variationsPrice = Object.values(selectedPrices)?.reduce(
      (total, price) => total + (price || 0),
      0
    );
    return (basePrice + variationsPrice) * count;
  };

  return (
    <Modal className="customized-modal" size="lg" show={show} centered>
      <Modal.Header closeButton onClick={() => setShow(false)}>
        <Modal.Title>{selectedProduct?.Name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="add_toCart_modal_div">
            <Form.Label className="modal_title">
              {selectedProduct?.Name}
            </Form.Label>
            <Form.Text className="price_in_modal">
              <div
                className="counter-container"
                style={{
                  display: "inline-flex",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <button
                  type="button"
                  className="counter-button"
                  onClick={decrement}
                >
                  -
                </button>
                <span className="counter-value">{count}</span>
                <button
                  type="button"
                  className="counter-button"
                  onClick={increment}
                >
                  +
                </button>
              </div>
              ${calculateTotalPrice().toFixed(2)}
            </Form.Text>
          </Form.Group>
          <Form.Group className="add_toCart_modal_div">
            <Form.Text className="product_description">
              {selectedProduct?.description}
            </Form.Text>
          </Form.Group>
          {variations &&
            variations
              .filter((i) => i.name !== "Syrups")
              .map((variation) => (
                <Form.Group
                  className="add_toCart_modal_div"
                  key={variation.productVariationID}
                >
                  <Form.Label className="modal_custom_text">
                    {variation.name}
                  </Form.Label>
                  <div>
                    {variation?.values?.map((value) => (
                      <Form.Check
                        key={value.productVariationValueID}
                        inline
                        className="modal_radio_buttons"
                        label={value.title}
                        name={variation.name}
                        type="radio"
                        id={`${variation.name}-${value.productVariationValueID}`}
                        onChange={() =>
                          handleVariationChange(
                            variation?.name,
                            value?.productVariationValueID,
                            value?.price
                          )
                        }
                        checked={
                          selectedVariations[variation.name] ===
                          value.productVariationValueID
                        }
                      />
                    ))}
                  </div>
                </Form.Group>
              ))}
          <Form.Group className="add_toCart_modal_div">
            <Form.Label className="modal_custom_text">Syrups</Form.Label>
            <Select
              options={syrupOptions}
              value={selectedSyrup}
              onChange={handleSyrupChange}
              placeholder="Select a Syrup"
              className="custom_select_modal"
              classNamePrefix="custom_dropdown_modal"
            />
          </Form.Group>
        </Form>
      </Modal.Body>

      <Modal.Footer
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Button
          onClick={handleAddToCart}
          className="addToCart_button mt-3"
          variant="primary"
        >
          Add To Cart
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalCard;
