import React, { useState, useEffect } from "react";
import Sidebar from "../../Common/Sidebar";
import Header from "../../Common/Header";
import { PostJsonData } from "../../Api/globalApi";
import { useLoading } from "../../Context/LoadingContext";
import { Container, Row, Col } from "react-bootstrap";
import {
  CTable,
  CTableBody,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CTableDataCell,
  CTableCaption,
  CBadge,
  CTooltip,
} from "@coreui/react";
import PaginationComponent from "../../Common/Pagination";

const Buyers = () => {
  const { setLoading } = useLoading();
  const [buyers, setBuyers] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  // Fetch buyers data from the API
  const fetchBuyers = async (searchText, pageNo) => {
    try {
      const jsonData = {
        isFilter: 0,
        isSubscription: 0,
        pageNo: pageNo,
        searchText: searchText ? searchText : "",
      };
      setLoading(true);
      const response = await PostJsonData("/Api/getBuyers", jsonData);
      if (response && response.apiResponseCode === "1001") {
        setBuyers(response.data);
        setPageLimit(response?.pageLimit);
        setTotalRecords(response?.totalRecords);
      } else {
        setBuyers(response?.data);
        setPageLimit(response?.pageLimit);
        setTotalRecords(response?.totalRecords);
      }
    } catch (error) {
      console.log("Failed to fetch buyers:", error);
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   fetchBuyers();
  // }, []);

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchBuyers(searchText, 1);
      setPageNo(1);
    }, 700);

    return () => {
      clearTimeout(handler);
    };
  }, [searchText]);

  const callGetData = (page) => {
    fetchBuyers(searchText, page);
  };

  return (
    <Container fluid>
      <Row className="main_container">
        <Col lg={2} className="outer_sidebar padding_0">
          <Sidebar />
        </Col>

        <Col lg={10} className="outer_main_container">
          <div className="main_home_outer">
            <Header setSearchText={setSearchText} searchText={searchText} />

            <Container fluid className="mt-4">
              <CTable
                className="category"
                style={{ textAlign: "left" }}
                responsive
              >
                <CTableHead>
                  <CTableRow>
                    <CTableHeaderCell>Sr.No</CTableHeaderCell>
                    <CTableHeaderCell>Image</CTableHeaderCell>
                    <CTableHeaderCell>Buyer Name</CTableHeaderCell>
                    <CTableHeaderCell>Email</CTableHeaderCell>
                    <CTableHeaderCell>Mobile No</CTableHeaderCell>
                    <CTableHeaderCell>D.O.B</CTableHeaderCell>
                    <CTableHeaderCell>Subscription</CTableHeaderCell>
                    <CTableHeaderCell>Created At</CTableHeaderCell>
                    <CTableHeaderCell>Status</CTableHeaderCell>
                  </CTableRow>
                </CTableHead>
                <CTableBody>
                  {buyers?.length > 0 ? (
                    buyers?.map((buyer, index) => (
                      <CTableRow key={buyer.buyerID}>
                        <CTableDataCell>{index + 1}</CTableDataCell>
                        <CTableDataCell>
                          <img
                            src={buyer?.imageURL || "./images/noimagefound.png"} // Default if no URL
                            alt="not found"
                            className="buyer_img"
                            onError={(e) => {
                              e.target.onerror = null; // Prevents infinite loop in case fallback image also fails
                              e.target.src = "./images/noimagefound.png"; // Set fallback image
                            }}
                          />
                        </CTableDataCell>
                        <CTableDataCell>
                          {buyer.name || "N/A"} (
                          {buyer.gender ? buyer.gender : "N/A"})
                        </CTableDataCell>
                        <CTableDataCell>{buyer.email || "N/A"}</CTableDataCell>
                        <CTableDataCell>
                          {buyer.mobileNo || "N/A"}
                        </CTableDataCell>
                        <CTableDataCell>
                          {/* <CTooltip content="Date of birth"> */}
                          <p>{buyer.dob || "N/A"}</p>
                          {/* </CTooltip> */}
                          <br />
                          <div>
                            {/* <CTooltip content="Age"> */}
                            <p>{buyer?.age ? buyer?.age : "N/A"}</p>
                            {/* </CTooltip> */}
                          </div>
                        </CTableDataCell>
                        <CTableDataCell>
                          <p
                            className={
                              buyer.isSubscription
                                ? "paid-color"
                                : "unpaid-color"
                            }
                          >
                            {buyer.isSubscription ? "Yes" : "No"}
                          </p>
                        </CTableDataCell>
                        <CTableDataCell>
                          {buyer.createdAt
                            ? new Date(buyer.createdAt).toLocaleString()
                            : "N/A"}
                        </CTableDataCell>
                        <CTableDataCell>
                          {buyer.isVerified ? (
                            <CBadge
                              style={{ padding: "7px 8px" }}
                              color="success"
                            >
                              Verified
                            </CBadge>
                          ) : (
                            <CBadge
                              style={{ padding: "7px 8px" }}
                              color="danger"
                            >
                              Not Verified
                            </CBadge>
                          )}
                        </CTableDataCell>{" "}
                      </CTableRow>
                    ))
                  ) : (
                    <CTableRow>
                      <CTableDataCell colSpan="9" className="text-center">
                        No buyers found.
                      </CTableDataCell>
                    </CTableRow>
                  )}
                </CTableBody>
                <PaginationComponent
                  totalRecords={totalRecords}
                  pageLimit={pageLimit}
                  setTotalRecords={setTotalRecords}
                  setPageLimit={setPageLimit}
                  setPageNo={setPageNo}
                  pageNo={pageNo}
                  callGetData={callGetData}
                />
              </CTable>
            </Container>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Buyers;
