import React from 'react';
import { Modal } from 'react-bootstrap';
// import './OrderSuccessModal.css'; // Import the CSS file

const SubSuccessModal = (props) => {
    // const [show, setShow] = useState(true);

    // const handleClose = () => setShow(false);

    return (
        <Modal
            show={props.subSuccessShow}
            onHide={() => props.setSubSuccessShow(false)}
            centered
            className="order-success-modal"
        >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                <img className='order-success-img mb-5' src="./images/coffe-sub-plan.svg" alt="Order Success" />
                {/* <h5 className='mb-4'>Order success</h5> */}
                <p className='sub-success-text mb-0'>Congratulations, You have </p>
                <p className='sub-success-text mb-5'>Premium Coffee Plan</p>
                <div className='order_sucess_button_container'>
                    {/* <Button variant="dark" onClick={() => props.setSubSuccessShow(false)}>
                        Explore Now                    </Button> */}
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default SubSuccessModal;
