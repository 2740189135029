import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import {
  SwipeableList,
  SwipeableListItem,
  SwipeAction,
  TrailingActions,
} from "react-swipeable-list";
import "react-swipeable-list/dist/styles.css";
import Swal from "sweetalert2";
import { PostJsonData } from "../Api/globalApi";
import { useLoading } from "../Context/LoadingContext";
import { useNavigate } from "react-router-dom";

function OffcanvasComponent() {
  const navigate = useNavigate();
  const {
    setLoading,
    offCanvasShow,
    setOffcanvasShow,
    setSelectedCheckoutData,
    setOrderConfirm,
  } = useLoading();
  const [cartItems, setCartItems] = useState([]);
  const [count, setCount] = useState({});

  useEffect(() => {
    fetchCartProducts();
  }, []);

  const fetchCartProducts = async () => {
    const sessionKey = localStorage.getItem("sessionKey"); // Retrieve session key from local storage
    const payload = {
      searchText: "", // Adjust as necessary
      sessionKey: sessionKey,
    };
    setLoading(true);
    try {
      const response = await PostJsonData("/getCartProducts", payload);
      if (response.apiResponseCode === "1001") {
        setLoading(false);
        setCartItems(response.data); // Set the fetched data
        // Initialize counts for each cart item
        const initialCounts = {};
        response.data.forEach((item) => {
          initialCounts[item.productCartID] = item.quantity || 1; // Start quantity based on existing quantity
        });
        setCount(initialCounts);
      } else {
        setLoading(false);
        // Swal.fire(
        //   "Error!",
        //   response.apiResponseMsg || "Failed to fetch cart items.",
        //   "error"
        // );
      }
    } catch (error) {
      setLoading(false);
      console.log("Error fetching cart products:", error);
      Swal.fire("Error!", "Failed to fetch cart products.", "error");
    }
  };

  const updateQuantity = async (productCartID, quantity) => {
    const sessionKey = localStorage.getItem("sessionKey");
    const payload = {
      productCartID: productCartID,
      quantity: quantity,
      sessionKey: sessionKey,
    };
    setLoading(true);
    try {
      const response = await PostJsonData("/updateCartProduct", payload);
      if (response.apiResponseCode === "1001") {
        setLoading(false);
        Swal.fire("Success!", "Quantity updated successfully.", "success");
      } else {
        setLoading(false);
        Swal.fire(
          "Error!",
          response.apiResponseMsg || "Failed to update quantity.",
          "error"
        );
      }
    } catch (error) {
      setLoading(false);
      console.log("Error updating cart product quantity:", error);
      Swal.fire("Error!", "Failed to update cart product quantity.", "error");
    }
  };

  const increment = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to increase the quantity?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, increase it!",
      cancelButtonText: "No, cancel!",
    }).then((result) => {
      if (result.isConfirmed) {
        setCount((prevCounts) => {
          const newCount = (prevCounts[id] || 1) + 1;
          updateQuantity(id, newCount); // Update the quantity via API
          return { ...prevCounts, [id]: newCount };
        });
      }
    });
  };

  const decrement = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to decrease the quantity?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, decrease it!",
      cancelButtonText: "No, cancel!",
    }).then((result) => {
      if (result.isConfirmed) {
        setCount((prevCounts) => {
          const newCount = Math.max((prevCounts[id] || 1) - 1, 1); // Prevent going below 1
          updateQuantity(id, newCount); // Update the quantity via API
          return { ...prevCounts, [id]: newCount };
        });
      }
    });
  };

  const handleDelete = async (productCartID) => {
    const sessionKey = localStorage.getItem("sessionKey"); // Retrieve session key from local storage
    const payload = {
      productCartID: productCartID,
      sessionKey: sessionKey,
    };
    setLoading(true);
    try {
      const response = await PostJsonData("/removeCartProduct", payload);
      if (response.apiResponseCode === "1001") {
        setLoading(false);
        setCartItems((prevItems) =>
          prevItems.filter((item) => item.productCartID !== productCartID)
        );
        Swal.fire({
          title: "Success!",
          text: response.apiResponseMsg,
          icon: "success",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            fetchCartProducts(); // Re-fetch cart products to ensure the list is updated
          }
        });
      } else {
        setLoading(false);
        Swal.fire(
          "Error!",
          response.apiResponseMsg || "Failed to remove item from cart.",
          "error"
        );
      }
    } catch (error) {
      setLoading(false);
      console.log("Error removing cart product:", error);
      Swal.fire("Error!", "Failed to remove item from cart.", "error");
    }
  };

  const trailingActions = (item) => (
    <TrailingActions>
      <SwipeAction
        destructive={true}
        onClick={() => handleDelete(item.productCartID)}
      >
        <div className="delete-action">
          <img
            style={{ margin: "auto" }}
            src="./images/Delete.svg"
            alt="Delete"
          />
        </div>
      </SwipeAction>
    </TrailingActions>
  );

  const calculateTotalPrice = () => {
    return cartItems
      .reduce(
        (total, item) => total + item.price * (count[item.productCartID] || 1),
        0
      )
      .toFixed(2);
  };

  return (
    <Offcanvas
      className="custom_offcanvas_outer"
      placement="end"
      scroll={true}
      backdrop={false}
      show={offCanvasShow}
      onHide={() => setOffcanvasShow(false)}
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Your Cart</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <SwipeableList>
          {cartItems?.length ? (
            cartItems?.map((item) => (
              <SwipeableListItem
                key={item.productCartID}
                trailingActions={trailingActions(item)}
              >
                <div className="item-container">
                  <img
                    src={item?.imageURL?.String || "./images/noimagefound.png"} // Default if no URL
                    alt="not found"
                    className="item-image"
                    onError={(e) => {
                      e.target.onerror = null; // Prevents infinite loop in case fallback image also fails
                      e.target.src = "./images/noimagefound.png"; // Set fallback image
                    }}
                  />
                  <div className="item-details">
                    <div className="item-text">{item.productName}</div>
                    <div className="item-description">
                      {(() => {
                        try {
                          const details = JSON.parse(
                            item.productVariationDetail
                          ); // Parse the JSON string
                          if (Array.isArray(details)) {
                            return details
                              .map(
                                (detail) => `${detail.name}: ${detail.value}`
                              )
                              .join(", "); // Join key-value pairs with a comma
                          } else {
                            return item.productVariationDetail; // Fallback if not an array
                          }
                        } catch (e) {
                          console.log(
                            "Error parsing productVariationDetail:",
                            e
                          );
                          return item.productVariationDetail; // Fallback to original string if parsing fails
                        }
                      })()}
                    </div>
                    <div className="counter-container">
                      <button
                        className="counter-button"
                        onClick={() => decrement(item.productCartID)}
                      >
                        -
                      </button>
                      <span className="counter-value">
                        {count[item.productCartID] || 1}
                      </span>
                      <button
                        className="counter-button"
                        onClick={() => increment(item.productCartID)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div className="item-price">
                    $
                    {((count[item.productCartID] || 1) * item.price).toFixed(2)}
                  </div>
                </div>
              </SwipeableListItem>
            ))
          ) : (
            <div style={{ textAlign: "left", fontWeight: "600" }}>
              No Product Found
            </div>
          )}
        </SwipeableList>
        <div className="total-price">
          Total Price <span>${calculateTotalPrice()}</span>
        </div>
        <div className="button-container">
          <Button
            className="add-more-button"
            onClick={() => {
              setOffcanvasShow(false);
              navigate("/menu");
            }}
          >
            Add More
          </Button>
          <Button
            className="add-more-button"
            onClick={() => {
              setOffcanvasShow(false);
              setOrderConfirm(true);
              setSelectedCheckoutData(calculateTotalPrice());
            }}
          >
            Checkout
          </Button>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default OffcanvasComponent;
