import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Header from "../Common/Header";
import Sidebar from "../Common/Sidebar";
// import { RiEditLine } from "react-icons/ri";
import ProfileModal from "./profileModal";
// import { CButton } from "@coreui/react";
import SubscriptionModal from "./SubscriptionModal";
import SubscriptionPlanModal from "./SubPlanModal";
import SubscriptionDetailModal from "./SubDetailModal";
import SubConfirnmModal from "./SubscriptionConfrimModal";
import SubSuccessModal from "./SubSuccessModal";

const SubScription = () => {
    const [profileShow, setProfileShow] = useState(false);
    const [subModalShow, setSubModalShow] = useState(false);
    const [subPlanShow, setSubPlanShow] = useState(false);
    const [subDetailModal, setSubDetailModal] = useState(false);
    const [subConModal, setSubConModal] = useState(false);
    const [subSuccessShow, setSubSuccessShow] = useState(false);

    return (
        <>
            {subPlanShow && <SubscriptionPlanModal setSubPlanShow={setSubPlanShow} subPlanShow={subPlanShow} setSubDetailModal={setSubDetailModal} />}
            {profileShow && <ProfileModal profileShow={profileShow} setProfileShow={setProfileShow} />}
            {subModalShow && <SubscriptionModal setSubDetailModal={setSubDetailModal} setSubPlanShow={setSubPlanShow} setSubModalShow={setSubModalShow} subModalShow={subModalShow} />}
            {subDetailModal && <SubscriptionDetailModal subDetailModal={subDetailModal} setSubDetailModal={setSubDetailModal} setSubConModal={setSubConModal} />}
            {subConModal && <SubConfirnmModal subConModal={subConModal} setSubConModal={setSubConModal} setSubSuccessShow={setSubSuccessShow} />}
            {subSuccessShow && <SubSuccessModal subSuccessShow={subSuccessShow} setSubSuccessShow={setSubSuccessShow} />}
            <Container fluid>
                <Row className="main_container">
                    <Col lg={2} className="outer_sidebar padding_0">
                        <Sidebar />
                    </Col>

                    <Col lg={10} className="outer_main_container">
                        <div className="main_home_outer">
                            <Header />
                            <Container fluid>
                                <div className="category profile_bg">
                                    {/* <div className="profile_main"> */}
                                    <Row>
                                        <Col>
                                            <h4 className="sub-heading">Your Subscriptions</h4>
                                            <p className="sub-breadcrumbs">Home / Subscription</p>
                                        </Col>
                                        {/* <Col className="add-more-container">
                                            <CButton className="sub-add" onClick={() => setSubModalShow(true)}>Add more +</CButton>
                                        </Col> */}
                                    </Row>
                                    <Row className="mt-4">
                                        <Col lg={3}>
                                            <div className="subscription-option plus mb-4">
                                                <h5>designers collab. Elite Pass:</h5>
                                                <p style={{ textAlign: "left" }} className="mb-5">Duriation: Week</p>
                                                <div className='outer-sub-btn'>
                                                    <button className='get-sub-btn' onClick={() => setSubModalShow(true)}>Get Subscription</button>
                                                </div>                </div>
                                        </Col>
                                    </Row>
                                </div>
                                {/* </div> */}
                            </Container>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default SubScription;
