import React from "react";
// import { Modal } from "react-bootstrap";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { HiOutlineClock } from "react-icons/hi2";
import { SlCup } from "react-icons/sl";
import moment from "moment";
import { PostJsonData } from "../Api/globalApi";
import Swal from "sweetalert2";
import { useLoading } from "../Context/LoadingContext";
import {
  CBadge,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CModal,
  CModalBody,
  CModalHeader,
} from "@coreui/react";

const ChangeOrderStatus = (props) => {
  const { setLoading } = useLoading();
  const handleStatusUpdate = async (orderId, newStatus) => {
    try {
      // Check if the status is CANCELLED and show an input field for the reason
      if (newStatus === "CANCELLED") {
        // Show SweetAlert with an input field for reason
        const result = await Swal.fire({
          title: "Please provide a reason for cancellation",
          input: "textarea",
          inputPlaceholder: "Enter the reason...",
          showCancelButton: true,
          confirmButtonText: "Submit",
          inputValidator: (value) => {
            if (!value || value.trim() === "") {
              return "You must provide a reason for cancellation";
            }
          },
        });

        // If the user provided a reason, continue with the API call
        if (result.isConfirmed) {
          const reason = result.value; // Get the reason from the modal input

          // Proceed with the API call
          setLoading(true);
          const response = await PostJsonData("/Api/updateOrderStatus", {
            orderID: orderId,
            orderStatus: newStatus,
            description: reason, // Send reason for cancellation
          });

          if (response.apiResponseCode === "1001") {
            setLoading(false);
            Swal.fire({
              icon: "success",
              title: "Success",
              text: response.apiResponseMsg,
              allowOutsideClick: false,
            }).then((result) => {
              if (result.isConfirmed) {
                props?.setActiveKey(newStatus);
                props?.fetchOrders(newStatus);
                props.setOrderStats(false);
              }
            });
          } else {
            setLoading(false);
            Swal.fire({
              icon: "error",
              title: "Oops",
              text: response.apiResponseMsg,
            });
          }
        } else {
          // If no reason is provided, stop the process
          setLoading(false);
          return;
        }
      } else {
        // For other statuses, proceed as usual
        setLoading(true);
        const response = await PostJsonData("/Api/updateOrderStatus", {
          orderID: orderId,
          orderStatus: newStatus,
        });

        if (response.apiResponseCode === "1001") {
          setLoading(false);
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.apiResponseMsg,
          }).then((result) => {
            if (result.isConfirmed) {
              props?.setActiveKey(newStatus);
              props?.fetchOrders(newStatus);
              props.setOrderStats(false);
            }
          });
        } else {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops",
            text: response.apiResponseMsg,
          });
        }
      }
    } catch (error) {
      setLoading(false);
      console.log("Error updating order status:", error);
    }
  };

  return (
    <CModal
      visible={props.orderStats}
      onClose={() => props.setOrderStats(false)}
      centered
      className="order-status-modal"
    >
      <CModalHeader closeButton></CModalHeader>
      <CModalBody>
        <div className="outer_orderDetils" style={{ marginBottom: "3rem" }}>
          <div className="order-details">
            <span>#{props?.viewDetails?.orderNo}</span>
            <span>
              {moment(
                props?.viewDetails?.createdAt,
                "YYYY-MM-DD HH:mm:ss"
              ).format("DD MMM YY, h:mm A")}
            </span>
          </div>
          <CBadge
            style={{ padding: "7px 8px" }}
            color={
              props?.viewDetails?.orderStatus === "ACCEPTED" ||
              props?.viewDetails?.orderStatus === "DELIVERED"
                ? "success"
                : props?.viewDetails?.orderStatus === "PREPARING"
                ? "warning"
                : "secondary" // Default color
            }
          >
            {props?.viewDetails?.orderStatus}
          </CBadge>
          <CDropdown className="home_list_dropdown">
            <CDropdownToggle className="home_list_dropdownToggle">
              {" "}
              ...
            </CDropdownToggle>
            <CDropdownMenu>
              <CDropdownItem
                onClick={() =>
                  handleStatusUpdate(props?.viewDetails?.orderID, "PREPARING")
                }
              >
                PREPARING
              </CDropdownItem>
              <CDropdownItem
                onClick={() =>
                  handleStatusUpdate(props?.viewDetails?.orderID, "DELIVERED")
                }
              >
                DELIVERED
              </CDropdownItem>
              <CDropdownItem
                onClick={() =>
                  handleStatusUpdate(props?.viewDetails?.orderID, "CANCELLED")
                }
              >
                CANCELLED
              </CDropdownItem>
            </CDropdownMenu>
          </CDropdown>
        </div>
        <div className="order-steps">
          <div
            className={
              props?.viewDetails?.orderStatus === "ACCEPTED"
                ? "order-step accepted"
                : "order-step"
            }
          >
            <div>
              <IoIosCheckmarkCircleOutline className="accepted-order-stats" />
            </div>
            <div className="time">9:30</div>
            <div className="description">Order is accepted</div>
          </div>
          <div
            className={
              props?.viewDetails?.orderStatus === "PREPARING"
                ? "order-step accepted"
                : "order-step"
            }
          >
            <div>
              <HiOutlineClock className="accepted-order-pending" />
            </div>
            <div className="time">9:35</div>
            <div className="order-step accepted description">
              Your order is preparing
            </div>
          </div>
          <div
            className={
              props?.viewDetails?.orderStatus === "DELIVERED"
                ? "order-step accepted"
                : "order-step"
            }
          >
            <div>
              <SlCup className="accepted-order-pending" />
            </div>
            <div className="time">9:55</div>
            <div className="description">Your order is delivered</div>
          </div>
        </div>
      </CModalBody>
    </CModal>
  );
};

export default ChangeOrderStatus;
