import {
  CButton,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CFormFeedback,
  CContainer,
  CRow,
} from "@coreui/react";
import React, { useState } from "react";
import { PostJsonData } from "../Api/globalApi";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useLoading } from "../Context/LoadingContext";

const ResetPasswordForm = () => {
  const navigate = useNavigate();
  const {setLoading} = useLoading();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showErrors, setShowErrors] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowErrors(true);

    if (!username || !password || password !== confirmPassword) {
      return;
    }

    const jsonData = {
      username: username.trim(),
      password: password.trim(),
      confirmPassword: confirmPassword.trim(),
    };
    try {
      setLoading(true);
      const response = await PostJsonData("/resetPassword", jsonData);

      if (response.apiResponseCode === "1001") {
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.apiResponseMsg,
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/");
          }
        });
        // Optionally redirect or clear the form here
      } else {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops",
          text: response.apiResponseMsg,
        });
      }
    } catch (error) {
      setLoading(false);
      console.log("Reset password failed:", error);
      Swal.fire({
        icon: "error",
        title: "Oops",
        text: error.message || "An unexpected error occurred.",
      });
    }
  };

  return (
    <>
      <CContainer fluid className="outer_container m-0 p-0">
        <CRow className="w-100 m-0">
          <CCol className="first_half">
            <img src="./images/loginImage.svg" alt="Login Illustration" />
          </CCol>
          <CCol className="second_half">
            <div className="inner_second_half">
              <div className="heading_log">Reset Your Password</div>
              <CForm className="form_custom" noValidate onSubmit={handleSubmit}>
                <div className="mb-3">
                  <CFormLabel className="label_custom">Username</CFormLabel>
                  <CFormInput
                    type="text"
                    placeholder="Enter your username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value.trim())}
                    required
                    invalid={showErrors && !username}
                  />
                  <CFormFeedback invalid>Username is required.</CFormFeedback>
                </div>
                <div className="mb-2">
                  <CFormLabel className="label_custom">New Password</CFormLabel>
                  <CFormInput
                    type="password"
                    placeholder="Enter your new password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value.trim())}
                    required
                    invalid={showErrors && !password}
                  />
                  <CFormFeedback invalid>Password is required.</CFormFeedback>
                </div>
                <div className="mb-2">
                  <CFormLabel className="label_custom">
                    Confirm Password
                  </CFormLabel>
                  <CFormInput
                    type="password"
                    placeholder="Confirm your new password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value.trim())}
                    required
                    invalid={showErrors && password !== confirmPassword}
                  />
                  <CFormFeedback invalid>
                    Password confirmation does not match.
                  </CFormFeedback>
                </div>
                <CButton type="submit" className="signIn_btn mt-3">
                  Reset Password
                </CButton>
              </CForm>
            </div>
          </CCol>
        </CRow>
      </CContainer>
    </>
  );
};

export default ResetPasswordForm;
