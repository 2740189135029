import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useLoading } from "../Context/LoadingContext";
// import './OrderSuccessModal.css'; // Import the CSS file

const OrderSuccessModal = (props) => {
  const { orderStatus, setOrderStatus } = useLoading();
  const navigate = useNavigate();
  return (
    <Modal
      show={orderStatus}
      onHide={() => setOrderStatus(false)}
      centered
      className="order-success-modal"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <img
          className="order-success-img mb-5"
          src="./images/orderSuccess.svg"
          alt="Order Success"
        />
        <h5 className="mb-4">Order success</h5>
        <p className="mb-0">Your order has been placed successfully!</p>
        <p className="mb-5"> For more details, go to My Orders.</p>
        <div className="order_sucess_button_container">
          <Button
            variant="dark"
            onClick={() => {
              navigate("/home", { state: { section: "order" } })
              setOrderStatus(false);
            }}
          >
            Go To Home
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default OrderSuccessModal;
