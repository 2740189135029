import React, { useEffect, useState } from "react";
import Header from "../../Common/Header";
import Sidebar from "../../Common/Sidebar";
import {
  CTable,
  CTableBody,
  CTableCaption,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CBadge,
  CTabContent,
  CTabPane,
  CNav,
  CNavItem,
  CNavLink,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
  CDropdown,
} from "@coreui/react";
import { Col, Container, Row } from "react-bootstrap";
import PaginationComponent from "../../Common/Pagination";
import OrderDetailsModal from "../../Components/OrderDetailsModal";
import { PostJsonData } from "../../Api/globalApi";
import { useLoading } from "../../Context/LoadingContext";
// import Swal from "sweetalert2";
import ChangeOrderStatus from "../../Components/ChangeOrderStatus";
import moment from "moment";
import { useLocation } from "react-router-dom";

const getStatusBadge = (status) => {
  switch (status) {
    case "ACCEPTED":
    case "DELIVERED":
      return (
        <CBadge style={{ padding: "7px 8px" }} color="success">
          {status}
        </CBadge>
      );
    case "PREPARING":
      return (
        <CBadge style={{ padding: "7px 8px" }} color="warning">
          {status}
        </CBadge>
      );
    case "CANCELLED":
      return (
        <CBadge style={{ padding: "7px 8px" }} color="danger">
          {status}
        </CBadge>
      );
    default:
      return (
        <CBadge style={{ padding: "7px 8px" }} color="secondary">
          {status}
        </CBadge>
      );
  }
};

const Home = () => {
  const { setLoading } = useLoading();
  const location = useLocation();
  const [activeKey, setActiveKey] = useState("");
  const [viewDetails, setViewDetails] = useState();
  const [orders, setOrders] = useState([]);
  // const [allOrdersData, setAllOrdersData] = useState([]);
  const [orderDetailShow, setOrderDetailShow] = useState(false);
  const [orderStats, setOrderStats] = useState(false);
  const [apiResponseMsg, setApiResponseMsg] = useState("");
  const [searchText, setSearchText] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [statusTotalRecords, setStatusTotalRecords] = useState({
    ALL: 0,
    ACCEPTED: 0,
    PREPARING: 0,
    DELIVERED: 0,
    CANCELLED: 0,
  });

  const fetchOrders = async (activeKey = "", searchText = "", pageNo) => {
    setLoading(true);
    try {
      const response = await PostJsonData("/Api/getOrderList", {
        orderStatus: activeKey,
        searchText: searchText,
        pageNo: pageNo,
      });

      if (response.apiResponseCode === "1001") {
        setOrders(response.data);
        setPageLimit(response?.pageLimit);
        setTotalRecords(response?.totalRecords);
        if (activeKey === "") {
          const acceptedOrders = response?.data?.filter(
            (i) => i.orderStatus === "ACCEPTED"
          )?.length;
          localStorage.setItem("AcceptedOrders", acceptedOrders);
          // setAllOrdersData(response.data);
        }
        setApiResponseMsg("");
      } else {
        setOrders([]);
        setApiResponseMsg(response.apiResponseMsg || "No order found");
      }
    } catch (error) {
      console.log("Error fetching orders:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchOrdersByStatus = async (status, searchText, pageNo = 1) => {
    try {
      const response = await PostJsonData("/Api/getOrderList", {
        orderStatus: status,
        searchText: searchText,
        pageNo: pageNo,
      });
  
      if (response.apiResponseCode === "1001") {
        const totalRecords = response?.totalRecords;
  
        // Update state with functional state update to avoid overwriting previous values
        setStatusTotalRecords((prevState) => {
          const updatedState = { ...prevState };
  
          if (status === "") {
            updatedState.ALL = totalRecords;
          } else {
            updatedState[status] = totalRecords;
          }
  
          return updatedState;
        });
  
        return response?.data; // Return the orders data for the given status
      } else {
        return []; // Return empty array if no data is found
      }
    } catch (error) {
      console.log("Error fetching orders by status:", error);
      return [];
    }
  };
  
  // useEffect(() => {
  //   fetchOrders();
  // }, []);

  useEffect(() => {
    const statuses = ["", "ACCEPTED", "PREPARING", "DELIVERED", "CANCELLED"];
    statuses?.forEach(async (status) => {
      await fetchOrdersByStatus(status, "", 1);
    });
  }, []);

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchOrders(activeKey, searchText, 1);
      setPageNo(1);
    }, 700);

    return () => {
      clearTimeout(handler);
    };
  }, [searchText]);

  const handleTabChange = (key) => {
    setActiveKey(key);
    fetchOrders(key, searchText, 1);
    setPageNo(1);
  };

  useEffect(() => {
    if (location?.state?.section == "notification") {
      setActiveKey("ACCEPTED");
    } 
    if (location?.state?.section == "order") {
      // setActiveKey("");
      fetchOrders();
    } 
  }, [location]);

  const renderTableRows = (ordersToRender) => {
    return orders.length > 0 ? (
      ordersToRender?.map((order) => (
        <CTableRow key={order.orderID}>
          <CTableDataCell>
            <input className="form-check-input" type="checkbox" />
          </CTableDataCell>
          <CTableDataCell>
            <div
              className="d-flex gap-2"
              onClick={() => {
                setOrderDetailShow(true);
                setViewDetails(order);
              }}
            >
              <div>
                <div>Order No: {order.orderNo}</div>
                <div className="table_id">ID: #{order.orderID}</div>
              </div>
            </div>
          </CTableDataCell>
          <CTableDataCell>
            {order.description.String ? order.description.String : "N/A"}
          </CTableDataCell>
          <CTableDataCell>
            <p className={order?.isPaid ? "paid-color" : "unpaid-color"}>
              {order.isPaid ? "Paid" : "Unpaid"}
            </p>
          </CTableDataCell>
          <CTableDataCell>${order.price}</CTableDataCell>
          <CTableDataCell>
            <p className={order?.isSubscription ? "paid-color" : "unpaid-color"}>
              {order?.isSubscription ? "Yes" : "No"}
            </p>
          </CTableDataCell>
          <CTableDataCell>{getStatusBadge(order.orderStatus)}</CTableDataCell>
          <CTableDataCell>
            {moment(order?.createdAt, "YYYY-MM-DD HH:mm:ss").format(
              "DD MMM YY, h:mm A"
            )}
          </CTableDataCell>
          <CTableDataCell>
            <CDropdown className="home_list_dropdown">
              <CDropdownToggle className="home_list_dropdownToggle">
                ...
              </CDropdownToggle>
              <CDropdownMenu>
                <CDropdownItem
                  onClick={() => {
                    setOrderDetailShow(true);
                    setViewDetails(order);
                  }}
                >
                  Order Details
                </CDropdownItem>
                <CDropdownItem
                  onClick={() => {
                    setViewDetails(order);
                    setOrderStats(true);
                  }}
                >
                  Change Order Status
                </CDropdownItem>
              </CDropdownMenu>
            </CDropdown>
          </CTableDataCell>
        </CTableRow>
      ))
    ) : (
      <CTableRow>
        <CTableDataCell colSpan={20}>{apiResponseMsg}</CTableDataCell>
      </CTableRow>
    );
  };

  const callGetData = (page) => {
    fetchOrders(activeKey, searchText, page);
  };

  return (
    <>
      {orderDetailShow && (
        <OrderDetailsModal
          viewDetails={viewDetails}
          setViewDetails={setViewDetails}
          orderDetailShow={orderDetailShow}
          setOrderDetailShow={setOrderDetailShow}
        />
      )}
      {orderStats && (
        <ChangeOrderStatus
          setActiveKey={setActiveKey}
          viewDetails={viewDetails}
          setViewDetails={setViewDetails}
          orderStats={orderStats}
          setOrderStats={setOrderStats}
          fetchOrders={fetchOrders}
        />
      )}
      <Container fluid>
        <Row className="main_container">
          <Col lg={2} className="outer_sidebar padding_0">
            <Sidebar />
          </Col>
          <Col lg={10} className="outer_main_container">
            <div className="main_home_outer">
              <Header
                // activeKey={activeKey}
                setSearchText={setSearchText}
                // fetchOrders={fetchOrders}
                // handleSearch={handleSearch} // Pass the search handler to the Header
              />
              <div className="category home_table_tabs">
                <CNav variant="tabs">
                  <CNavItem>
                    <CNavLink
                      active={activeKey === ""}
                      onClick={() => handleTabChange("")}
                    >
                      All Orders {`(${statusTotalRecords?.ALL})`}
                    </CNavLink>
                  </CNavItem>
                  <CNavItem>
                    <CNavLink
                      active={activeKey === "ACCEPTED"}
                      onClick={() => handleTabChange("ACCEPTED")}
                    >
                      Accepted Orders{" "}
                      {`(${statusTotalRecords?.ACCEPTED})`}
                    </CNavLink>
                  </CNavItem>
                  <CNavItem>
                    <CNavLink
                      active={activeKey === "PREPARING"}
                      onClick={() => handleTabChange("PREPARING")}
                    >
                      Preparing Orders{" "}
                      {`(${statusTotalRecords?.PREPARING})`}
                    </CNavLink>
                  </CNavItem>
                  <CNavItem>
                    <CNavLink
                      active={activeKey === "DELIVERED"}
                      onClick={() => handleTabChange("DELIVERED")}
                    >
                      Delivered Orders{" "}
                      {`(${statusTotalRecords?.DELIVERED})`}
                    </CNavLink>
                  </CNavItem>
                  <CNavItem>
                    <CNavLink
                      active={activeKey === "CANCELLED"}
                      onClick={() => handleTabChange("CANCELLED")}
                    >
                      Cancelled Orders{" "}
                      {`(${statusTotalRecords?.CANCELLED})`}
                    </CNavLink>
                  </CNavItem>
                </CNav>
                <CTabContent style={{ maxHeight: "60vh", overflowY: "scroll" }}>
                  <CTabPane visible={activeKey === ""}>
                    <CTable align="middle" caption="top" responsive>
                      <CTableCaption>All Orders</CTableCaption>
                      <CTableHead>
                        <CTableRow>
                          <CTableHeaderCell>Check</CTableHeaderCell>
                          <CTableHeaderCell>Order</CTableHeaderCell>
                          <CTableHeaderCell>Description</CTableHeaderCell>
                          <CTableHeaderCell>Payment Status</CTableHeaderCell>
                          <CTableHeaderCell>Price</CTableHeaderCell>
                          <CTableHeaderCell>Subscription</CTableHeaderCell>
                          <CTableHeaderCell>Status</CTableHeaderCell>
                          <CTableHeaderCell>Order Time</CTableHeaderCell>
                          <CTableHeaderCell>Action</CTableHeaderCell>
                        </CTableRow>
                      </CTableHead>
                      <CTableBody>{renderTableRows(orders)}</CTableBody>
                    </CTable>
                    <PaginationComponent
                      totalRecords={totalRecords}
                      pageLimit={pageLimit}
                      setTotalRecords={setTotalRecords}
                      setPageLimit={setPageLimit}
                      setPageNo={setPageNo}
                      pageNo={pageNo}
                      callGetData={callGetData}
                    />
                  </CTabPane>
                  <CTabPane visible={activeKey === "ACCEPTED"}>
                    <CTable align="middle" caption="top" responsive>
                      <CTableCaption>Accepted Orders</CTableCaption>
                      <CTableHead>
                        <CTableRow>
                          <CTableHeaderCell>Check</CTableHeaderCell>
                          <CTableHeaderCell>Order</CTableHeaderCell>
                          <CTableHeaderCell>Description</CTableHeaderCell>
                          <CTableHeaderCell>Payment Status</CTableHeaderCell>
                          <CTableHeaderCell>Price</CTableHeaderCell>
                          <CTableHeaderCell>Subscription</CTableHeaderCell>
                          <CTableHeaderCell>Status</CTableHeaderCell>
                          <CTableHeaderCell>Order Time</CTableHeaderCell>
                          <CTableHeaderCell>Action</CTableHeaderCell>
                        </CTableRow>
                      </CTableHead>
                      <CTableBody>{renderTableRows(orders)}</CTableBody>
                    </CTable>
                    <PaginationComponent
                      totalRecords={totalRecords}
                      pageLimit={pageLimit}
                      setTotalRecords={setTotalRecords}
                      setPageLimit={setPageLimit}
                      setPageNo={setPageNo}
                      pageNo={pageNo}
                      callGetData={callGetData}
                    />
                  </CTabPane>
                  <CTabPane visible={activeKey === "PREPARING"}>
                    <CTable align="middle" caption="top" responsive>
                      <CTableCaption>Preparing Orders</CTableCaption>
                      <CTableHead>
                        <CTableRow>
                          <CTableHeaderCell>Check</CTableHeaderCell>
                          <CTableHeaderCell>Order</CTableHeaderCell>
                          <CTableHeaderCell>Description</CTableHeaderCell>
                          <CTableHeaderCell>Payment Status</CTableHeaderCell>
                          <CTableHeaderCell>Price</CTableHeaderCell>
                          <CTableHeaderCell>Subscription</CTableHeaderCell>
                          <CTableHeaderCell>Status</CTableHeaderCell>
                          <CTableHeaderCell>Order Time</CTableHeaderCell>
                          <CTableHeaderCell>Action</CTableHeaderCell>
                        </CTableRow>
                      </CTableHead>
                      <CTableBody>{renderTableRows(orders)}</CTableBody>
                    </CTable>
                    <PaginationComponent
                      totalRecords={totalRecords}
                      pageLimit={pageLimit}
                      setTotalRecords={setTotalRecords}
                      setPageLimit={setPageLimit}
                      setPageNo={setPageNo}
                      pageNo={pageNo}
                      callGetData={callGetData}
                    />
                  </CTabPane>
                  <CTabPane visible={activeKey === "DELIVERED"}>
                    <CTable align="middle" caption="top" responsive>
                      <CTableCaption>Delivered Orders</CTableCaption>
                      <CTableHead>
                        <CTableRow>
                          <CTableHeaderCell>Check</CTableHeaderCell>
                          <CTableHeaderCell>Order</CTableHeaderCell>
                          <CTableHeaderCell>Description</CTableHeaderCell>
                          <CTableHeaderCell>Payment Status</CTableHeaderCell>
                          <CTableHeaderCell>Price</CTableHeaderCell>
                          <CTableHeaderCell>Subscription</CTableHeaderCell>
                          <CTableHeaderCell>Status</CTableHeaderCell>
                          <CTableHeaderCell>Order Time</CTableHeaderCell>
                          <CTableHeaderCell>Action</CTableHeaderCell>
                        </CTableRow>
                      </CTableHead>
                      <CTableBody>{renderTableRows(orders)}</CTableBody>
                    </CTable>
                    <PaginationComponent
                      totalRecords={totalRecords}
                      pageLimit={pageLimit}
                      setTotalRecords={setTotalRecords}
                      setPageLimit={setPageLimit}
                      setPageNo={setPageNo}
                      pageNo={pageNo}
                      callGetData={callGetData}
                    />
                  </CTabPane>
                  <CTabPane visible={activeKey === "CANCELLED"}>
                    <CTable align="middle" caption="top" responsive>
                      <CTableCaption>Cancelled Orders</CTableCaption>
                      <CTableHead>
                        <CTableRow>
                          <CTableHeaderCell>Check</CTableHeaderCell>
                          <CTableHeaderCell>Order</CTableHeaderCell>
                          <CTableHeaderCell>Description</CTableHeaderCell>
                          <CTableHeaderCell>Payment Status</CTableHeaderCell>
                          <CTableHeaderCell>Price</CTableHeaderCell>
                          <CTableHeaderCell>Subscription</CTableHeaderCell>
                          <CTableHeaderCell>Status</CTableHeaderCell>
                          <CTableHeaderCell>Order Time</CTableHeaderCell>
                          <CTableHeaderCell>Action</CTableHeaderCell>
                        </CTableRow>
                      </CTableHead>
                      <CTableBody>{renderTableRows(orders)}</CTableBody>
                    </CTable>
                    <PaginationComponent
                      totalRecords={totalRecords}
                      pageLimit={pageLimit}
                      setTotalRecords={setTotalRecords}
                      setPageLimit={setPageLimit}
                      setPageNo={setPageNo}
                      pageNo={pageNo}
                      callGetData={callGetData}
                    />
                  </CTabPane>
                </CTabContent>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Home;
