import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row } from 'react-bootstrap';

function SubConfirnmModal(props) {
    return (
        <>
            <Modal className='order_custom_modal' show={props.subConModal} onHide={() => props.setSubConModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title className='offcanvas-title'>Subscription confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-container">
                    <div className="outer_order_content">
                        <Form>
                            <Form.Group controlId="paymentMethod" className="card-radio">
                                <Form.Check
                                    type="radio"
                                    label="Cash on counter"
                                    className='custom_radio'
                                    style={{ alignItems: "center", justifyContent: "center", gap: "8px" }}
                                    name="paymentMethod"
                                    defaultChecked
                                />
                                <img src="./images/cash.svg" alt="Visa" style={{ width: '30px' }} />
                            </Form.Group>
                        </Form>
                        <div className="order-summary">
                            <div className="summary-row">
                                <span>Subtotal</span>
                                <span>$19.96</span>
                            </div>
                            <div className="summary-row">
                                <span>Tax (10%)</span>
                                <span>$1.00</span>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer style={{ border: 'none', paddingTop: '0' }}>
                    <Container fluid>
                        <Row>
                            <Col lg={4} className="">
                                <div className='total_price_order'>Total Price</div>
                                <div className='total_price_order_amnt'>$20.96</div>
                            </Col>
                            <Col lg={8}>
                                <Button className="order_pay_now" onClick={() => { props.setSubConModal(false); props.setSubSuccessShow(true) }}>
                                    Pay Now
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default SubConfirnmModal;
