import {
  CButton,
  CCol,
  CForm,
  CFormLabel,
  CFormFeedback,
  CContainer,
  CRow,
} from "@coreui/react";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import OtpInput from "react-otp-input";
import { PostJsonData } from "../Api/globalApi"; // Import your API function
import Swal from "sweetalert2"; // Import SweetAlert2 for notifications
import { useLoading } from "../Context/LoadingContext";

const Otp = (props) => {
  const navigate = useNavigate();
  const {setLoading} = useLoading();
  const location = useLocation();
  const [code, setCode] = useState("");
  const [showError, setShowError] = useState(false);

  const handleChange = (code) => {
    setCode(code);
    if (code.length < 4) {
      setShowError(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (code.length !== 5) {
      setShowError(true);
      return;
    }

    // Prepare the payload for the API request
    const jsonData = {
      email: location?.state?.userName, // Get the email from props
      otp: code, // Use the OTP code entered by the user
    };
    try {
      setLoading(true);
      // Call your API to verify the OTP
      const response = await PostJsonData("/verifyAccountEmail", jsonData);
      if (response.apiResponseCode === "1001") {
        setLoading(false);
        // If verification is successful, set the reset password flag to true
        // props?.otpShow(false);
        // props.setResetPassword(true);
        navigate("/resetPassword")
        // Optionally, navigate to another page or show a success message
        // navigate("/home"); // Redirect or navigate to desired page
      } else {
        setLoading(false);
        // Show an error message if the verification fails
        Swal.fire({
          icon: "error",
          title: "Oops",
          text: response.apiResponseMsg, // Display the error message from the API
        });
      }
    } catch (error) {
      setLoading(false);
      console.log("OTP verification failed:", error);
      Swal.fire({
        icon: "error",
        title: "Oops",
        text: error.message || "An unexpected error occurred.",
      });
    }
  };

  return (
    <>
      <CContainer fluid className="outer_container m-0 p-0">
        <CRow className="w-100 m-0">
          <CCol className="first_half">
            <img src="./images/loginImage.svg" alt="Login Illustration" />
          </CCol>
          <CCol className="second_half">
            <div className="inner_second_half">
              <div className="heading_log">OTP</div>
              <CForm className="form_custom" onSubmit={handleSubmit}>
                <div className="mb-3">
                  <CFormLabel className="label_custom">
                    Enter the code we just sent you in your email address.
                  </CFormLabel>
                  <OtpInput
                    value={code}
                    onChange={handleChange}
                    numInputs={5}
                    separator={<span style={{ width: "8px" }}></span>}
                    isInputNum={true}
                    shouldAutoFocus={true}
                    renderInput={(props) => <input {...props} />}
                    inputStyle={{
                      border:
                        showError && code.length < 4
                          ? "1px solid red"
                          : "1px solid #58595B2B",
                      borderRadius: "8px",
                      width: "50px",
                      height: "50px",
                      fontSize: "14px",
                      color: "#000",
                      fontWeight: "600",
                      outline: "none",
                      marginRight: "1rem",
                    }}
                    focusStyle={{
                      border: "1px solid #58595B2B",
                      outline: "none",
                    }}
                  />
                  {showError && code.length < 4 && (
                    <CFormFeedback invalid>
                      Please enter a valid 4-digit code.
                    </CFormFeedback>
                  )}
                  <div className="label_custom">
                    If you didn’t receive a code?
                  </div>
                  <div>Resend</div>
                </div>
                <CButton className="signIn_btn mt-3" type="submit">
                  Verify
                </CButton>
              </CForm>
            </div>
          </CCol>
        </CRow>
      </CContainer>
    </>
  );
};

export default Otp;
