import React from "react";
import "./Login.css";
import {
  CCol,
  CContainer,
  CRow,
} from "@coreui/react";
// import { GoogleLogin } from '@react-oauth/google';
import LoginForm from "./LoginForm";
// import Otp from "./Otp";

const Login = () => {
  // const [forgotEmail, setForgotEmail] = useState('');
  // const [otpShow,setOtpShow] = useState(false);
  // const [passwordShow,setPasswordShow] = useState(false);
  // const [resetPassword,setResetPassword] = useState(false);
  return (
    <div>
      <CContainer fluid className="outer_container m-0 p-0">
        <CRow className="w-100 m-0">
          <CCol className="first_half">
            <img src="./images/loginImage.svg" alt="Login Illustration" />
          </CCol>
          <LoginForm/>
         {/* {otpShow  &&(!resetPassword)&& (<Otp forgotEmail={forgotEmail} setForgotEmail={setForgotEmail} setResetPassword={setResetPassword}/>)} */}
         {/* {passwordShow &&(!otpShow)&& (<ForgotPassword forgotEmail={forgotEmail} setForgotEmail={setForgotEmail} otpShow={otpShow} setOtpShow={setOtpShow}/>)} */}
         {/* {resetPassword && (<ResetPassword resetPassword={resetPassword} setResetPassword={setResetPassword} otpShow={otpShow} setOtpShow={setOtpShow}/>)} */}
        </CRow>
      </CContainer>
    </div>
  );
};

export default Login;
