// import { CBadge } from '@coreui/react';
import React from 'react';
import { Modal } from 'react-bootstrap';
// import './SubscriptionDetailModal.css';

const SubscriptionDetailModal = (props) => {
    return (
        <Modal className='sub-detail-modal' show={props.subDetailModal} onHide={() => props.setSubDetailModal(false)} centered>
            <Modal.Header closeButton>
                <Modal.Title className='offcanvas-title p-1'>Tier 1: Rise and Shine</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {/* <div className="plan-header mt-2"> */}
                <div className="summary-row">
                    <span className='tier-price'>Week</span>
                    <span className='tier-price-inner'>$14.99</span>
                </div>
                {/* </div> */}
                <p className="subscriptions-title mt-3 mb-3">This includes one beverage per day of the following: </p>
                <p className="subscriptions-title-1 mt-3 mb-3">Hot and Iced Americanos, Hot and Iced Double Espressos, Hot and Iced Teas, 8 oz drinks  </p>
                <p className="subscriptions-title-1 mt-3 mb-3">Free alternative milks and flavors </p>
            </Modal.Body>
            <Modal.Footer className='justify-content-center'>
                <button className='sub-detail-checkout-btn' onClick={() => { props.setSubDetailModal(false); props.setSubConModal(true) }}>Checkout</button>
            </Modal.Footer>
        </Modal>
    );
};

export default SubscriptionDetailModal;
