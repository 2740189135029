import React from 'react';
import { Modal } from 'react-bootstrap';
import { CFormInput, CFormLabel } from '@coreui/react';
import { FiEdit2 } from 'react-icons/fi';

const ProfileModal = (props) => {
    return (
        <Modal
            show={props.profileShow}
            onHide={() => props.setProfileShow(false)}
            centered
            className="profile-modal"
            size='lg'
        >
            <Modal.Header closeButton>
                <Modal.Title className='offcanvas-title'>Your Profile</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="form-section">
                    <div className="d-flex justify-content-between align-items-center">
                        <h6>Account</h6>
                        <FiEdit2 className="edit-icon" />
                    </div>
                    <div className="profile_name_email">
                        <div className="form-group">
                            <CFormLabel>Email</CFormLabel>
                            <CFormInput type="email" value="jhondeo2398@gmail.com" readOnly />
                        </div>
                        <div className="form-group">
                            <CFormLabel>Change password</CFormLabel>
                            <CFormInput type="password" placeholder="************" readOnly />
                        </div>
                    </div>
                </div>
                <div className="form-section">
                    <h6>Personal details</h6>
                    {/* <div className="form-row"> */}
                    <div className="profile_name_email">

                        <div className="form-group">
                            <CFormLabel>Name</CFormLabel>
                            <CFormInput type="text" value="Jhon Deo" readOnly />
                        </div>
                        <div className="form-group">
                            <CFormLabel>Phone number</CFormLabel>
                            <CFormInput type="text" value="+1 3973 393 393" readOnly />
                        </div>
                    </div>
                    {/* </div> */}
                    {/* <div className="form-row">
                     */}
                    <div className="profile_name_email">

                        <div className="form-group">
                            <CFormLabel>Date of birth</CFormLabel>
                            <CFormInput type="text" value="19 Sep 1996" readOnly />
                        </div>
                        <div className="form-group">
                            <CFormLabel>Gender</CFormLabel>
                            <CFormInput type="text" value="Male" readOnly />
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ProfileModal;
