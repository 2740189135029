import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./App.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "@coreui/coreui/dist/css/coreui.min.css";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { LoadingProvider } from "./Context/LoadingContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
    <GoogleOAuthProvider>
      <LoadingProvider>
        <App />
      </LoadingProvider>
    </GoogleOAuthProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
