import React from "react";
import { CPagination, CPaginationItem } from "@coreui/react";

const PaginationComponent = ({
  totalRecords,
  pageLimit,
  pageNo,
  setPageNo,
  callGetData,
}) => {
  const totalPages = Math.ceil(totalRecords / pageLimit);

  if (totalPages <= 1) return null;

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setPageNo(newPage);
      callGetData(newPage); // Trigger API call on page change
    }
  };

  return (
    <CPagination className="pagination_custom" align="end">
      <CPaginationItem
        disabled={pageNo === 1}
        onClick={() => handlePageChange(pageNo - 1)}
      >
        <span className="pagination_next_prev">
          <img src="./images/previous.svg" alt="previous" /> Previous
        </span>
      </CPaginationItem>

      {Array.from({ length: totalPages }, (_, index) => (
        <CPaginationItem
          key={index}
          active={index + 1 === pageNo}
          onClick={() => handlePageChange(index + 1)}
        >
          {index + 1}
        </CPaginationItem>
      ))}

      <CPaginationItem
        disabled={pageNo === totalPages}
        onClick={() => handlePageChange(pageNo + 1)}
      >
        <span className="pagination_next_prev">
          Next <img src="./images/next.svg" alt="next_btn" />
        </span>
      </CPaginationItem>
    </CPagination>
  );
};

export default PaginationComponent;
