import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Header from "../Common/Header";
import Sidebar from "../Common/Sidebar";
// import Switch from "react-switch";
import { CFormSwitch } from "@coreui/react";


const Settings = () => {

    return (
        <>
            <Container fluid>
                <Row className="main_container">
                    <Col lg={2} className="outer_sidebar padding_0">
                        <Sidebar />
                    </Col>

                    <Col lg={10} className="outer_main_container">
                        <div className="main_home_outer">
                            <Header />
                            <Container fluid>
                                <div className="category profile_bg">
                                    {/* <div className="profile_main"> */}
                                    <Row>
                                        <Col>
                                            <h4 className="sub-heading">Setting</h4>
                                            <p className="sub-breadcrumbs">Home / Account / Setting</p>
                                        </Col>
                                    </Row>
                                    <Row className="mt-4">
                                        <Col lg={4}>
                                            <div className="profile_tabs">
                                                <div className="text-profile-div">
                                                    <img src="./Vector (Stroke).svg" alt="" />
                                                    <h2 className="textt mt-1">All Notification</h2>
                                                </div>
                                                <div>
                                                <CFormSwitch defaultChecked/>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={4}>
                                            <div className="profile_tabs">
                                                <div className="text-profile-div">
                                                    <img src="./Vector (Stroke).svg" alt="" />
                                                    <h2 className="textt mt-1">Badge Notification</h2>
                                                </div>
                                                <div>
                                                <CFormSwitch defaultChecked/>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                {/* </div> */}
                            </Container>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default Settings;
