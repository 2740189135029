import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CTooltip,
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import { PostJsonData } from "../Api/globalApi";
import { useLocation, useNavigate } from "react-router-dom";
import { useLoading } from "../Context/LoadingContext";
// import { IoCartOutline } from "react-icons/io5";
import { PiShoppingCartLight } from "react-icons/pi";

const Header = ({ setSearchText }) => {
  const location = useLocation();
  const { setOffcanvasShow } = useLoading();
  const acceptedOrders = localStorage.getItem("AcceptedOrders");
  const userName = localStorage.getItem("Name");
  const navigate = useNavigate();
  const [cartCount, setCartCount] = useState();
  const onLogout = async () => {
    try {
      await PostJsonData("/Api/logout", {}); // Hit the logout API
      localStorage.clear(); // Clear user data from local storage if needed
      navigate("/");
    } catch (error) {
      console.log("Logout failed:", error);
    }
  };

  // const handleKeyPress = (e) => {
  //   if (e.key === "Enter") {
  //     // fetchOrders(activeKey,e.target.value); // Trigger fetchOrders from the Home page
  //   }
  // };

  const handleSearchText = (e) => {
    if (e.target.value === "") {
      setSearchText("");
      // fetchOrders(activeKey,"");
    } else {
      setSearchText(e.target.value);
    }
  };
  const shouldShowSearch =
    location?.pathname !== "/profile" && location?.pathname !== "/reviews";

  const fetchCartProducts = async () => {
    const sessionKey = localStorage.getItem("sessionKey");
    const payload = {
      searchText: "", // Adjust as necessary
      sessionKey: sessionKey,
    };
    // setLoading(true);
    try {
      const response = await PostJsonData("/getCartProducts", payload);
      if (response.apiResponseCode === "1001") {
        // setLoading(false);
        setCartCount(response.data ? response.data?.length : 0);
      } else {
        // setLoading(false);
        // Swal.fire(
        //   "Error!",
        //   response.apiResponseMsg || "Failed to fetch cart items.",
        //   "error"
        // );
      }
    } catch (error) {
      // setLoading(false);
      console.log("Error fetching cart products:", error);
      // Swal.fire("Error!", "Failed to fetch cart products.", "error");
    }
  };

  useEffect(() => {
    fetchCartProducts();
  }, []);

  return (
    <div className="header_main_container">
      <div>
        {shouldShowSearch && (
          <input
            className="search_text"
            type="search"
            placeholder="Search"
            onChange={handleSearchText}
          />
        )}
      </div>
      <div
        className="notification"
        onClick={() =>
          navigate("/home", { state: { section: "notification" } })
        }
      >
        <img src="./images/notificationIcon.svg" alt="notification_alt" />
        <span className="background-notification-count">
          {/* <CTooltip content="Accepted Orders"> */}
          <span className="background-notification-count-inner">
            {acceptedOrders}
          </span>
          {/* </CTooltip> */}
        </span>
      </div>
      <div className="notification" onClick={() => setOffcanvasShow(true)}>
        <PiShoppingCartLight style={{ fontSize: "35px" }} />
        <span className="background-notification-count-cart">
          <span className="background-notification-count-inner">
            {cartCount ? cartCount : 0}
          </span>
        </span>
      </div>
      <img
        src="./images/user.png"
        alt="user"
        className="rounded-full object-cover"
        style={{ height: "40px" }}
      />
      <CDropdown className="relative">
        <CDropdownToggle
          style={{ paddingLeft: "0" }}
          className="flex items-center space-x-2 focus:outline-none appheader_menu_dropdown"
        >
          <span className="text-gray-700 font-medium">{userName}</span>
        </CDropdownToggle>
        <CDropdownMenu className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-lg shadow-lg">
          <CDropdownItem
            onClick={onLogout}
            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
          >
            Logout
          </CDropdownItem>
        </CDropdownMenu>
      </CDropdown>
    </div>
  );
};

export default Header;
