import React from 'react';
import { Modal } from 'react-bootstrap';
// import './OrderStatusModal.css'; // Import the CSS file
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { HiOutlineClock } from "react-icons/hi2";
import { SlCup } from "react-icons/sl";

const OrderStatsModal = (props) => {

    return (
        <Modal
            show={props.orderStats}
            onHide={() => props.setOrderStats(false)}
            centered
            className="order-status-modal"
        >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                <div className="outer_orderDetils" style={{marginBottom:"3rem"}}>
                    {/* <div className="order-details">
                        <span>#004958</span>
                        <span>30 March, 9:20AM</span>
                    </div> */}
                </div>
                <div className="order-steps">
                    <div className="order-step accepted">
                        <div ><IoIosCheckmarkCircleOutline className="accepted-order-stats" />
                        </div>
                        <div className="time">9:30</div>
                        <div className="description">Order is accepted</div>
                    </div>
                    <div className="order-step">
                        <div ><HiOutlineClock className='accepted-order-pending' />
                        </div>
                        <div className="time">9:35</div>
                        <div className="description">Your order is preparing</div>
                    </div>
                    <div className="order-step">
                        <div><SlCup className='accepted-order-pending' />
                        </div>
                        <div className="time">9:55</div>
                        <div className="description">Collect your order</div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default OrderStatsModal;
